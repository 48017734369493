import React from 'react'
import Layout from '../components/Layout'
import PaddedLayout from '../components/PaddedLayout'
import MachineNavigation from './MachineNavigation'
import './productFeatures.scss'
export default function ProductFeatures(props) {
    let machine = props.pageContext
    return (
        <Layout>
            <MachineNavigation machine={machine} page="features"/>
            <PaddedLayout>
            <h4>{machine.model} </h4>
            <h2>Features</h2>
            <div className="features-feature-div">
            <div>
                <h3>CARBON Walk Behind Floor Scrubber</h3>
                <p>Our CARBON Floor Scrubbers are not only loved for their long battery life but also because of their unbelievable scrubbing power.</p>
            </div>
            <div>
            </div>
            <div>The CARBON Scrubbers are available with Disk, Cylindrical and EDGE scrub heads.
</div>
            <div></div>
            </div>
            </PaddedLayout>
        </Layout>
    )
}
